import React from "react";
import {Helmet} from "react-helmet";
import ogImage from "../content/images/og_image.png";
import {languages} from "../../languages";

const Seo = ({lang, title, noIndex = false, description, location, slug}) => {

    const additionalTitle = title ? `– ${title}` : '';

    return (
        <Helmet>
            <title>{`Stefan Galler ${additionalTitle}`}</title>
            <html lang={lang}/>
            {noIndex && <meta name="robots" content="noindex"/>}
            {description && <meta name="description" content={description}/>}
            <meta property="og:title" content={`Stefan Galler ${additionalTitle}`}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={ogImage}/>
            {
                languages.map((language) => <link rel="alternate" hrefLang={language} href={`${location.origin}/${language}${slug}`}/>)
            }
        </Helmet>
    )
}

export default Seo
