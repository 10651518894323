import React, {useEffect, useState} from "react";
import Layout from "../layouts";
import Seo from "../components/Seo";
import {Link} from "gatsby";
import {FormattedMessage} from "react-intl";
import {StaticImage} from "gatsby-plugin-image";

const PrivacyPolicyDe = ({location}) => {

    const [optedIn, setOptedIn] = useState(true);
    useEffect(() => {
        window._paq?.push([function () {
            if (this.isUserOptedOut()) {
                setOptedIn(false);
            } else {
                setOptedIn(true);
            }
        }])
    })

    const toggle = () => {
        console.log(optedIn);
        if (optedIn) {
            window._paq?.push(['optUserOut']);
            setOptedIn(false);
        } else {
            window._paq?.push(['forgetUserOptOut']);
            setOptedIn(true);
        }
    }

    return (
        <Layout lang="de" showNav={false}>
            <Seo lang="de" title="Datenschutzbestimmungen" noIndex={true} location={location} slug="/privacy-policy"/>
            <div className="flex flex-row md:items-center">
                <div className="flex-1">
                    <Link className="cursor-pointer text-sm" to={`/de`}>{`❮ `}<FormattedMessage id="back"/> </Link>
                    <h1 className="text-2xl md:text-3xl font-bold mt-2 mb-8">Datenschutzbestimmungen</h1>
                    <div className="text-lg ">
                        <p>Diese Datenschutzbestimmungen erklären welche Daten auf dieser Seite verarbeitet werden..</p>
                        <h2>Verarbeitete Daten</h2>
                        <p>Es werden keine personenbezogenen Daten verarbeitet und keine Cookies verwendet.</p>
                        <p>Folgende Informationen werden für eine Benutzungstatistik verwendet:
                            Die Webseite von der auf diese Seite weitergeleitet wurde, welche Seiten auf stefangaller.at besucht werden,
                            das Datum und die Dauer des Besuchs, die anonymisierte IP Adresse, Informationen über das verwendete Gerät
                            (Typ, Betriebssystem, Display-Größe, Sprache, Land, Art des Browsers).
                        </p>
                        <p><Link className="underline" to="https://matomo.org" target="_blank">Matomo</Link> wird zum diesem Zweck verwendet. </p>
                        <h2>Opt-out</h2>
                        <p>Es ist auch möglich das Sammeln der Daten für diesen obengenannten Zweck zu deaktivieren. Dazu muss folgende Checkbox deaktiviert werden.</p>
                        <p><input className="mr-1" type='checkbox' checked={optedIn}
                                  onChange={toggle}/> {optedIn ? 'Aktiviert' : 'Deaktiviert'}</p>

                    </div>
                </div>
                <div className="hidden md:inline-block flex-1 ml-16">
                    <StaticImage
                        src="../content/images/placeholder.png" alt=""
                        quality={90}/>
                </div>
            </div>
        </Layout>
    )
}


export default PrivacyPolicyDe;